import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import { useEffect, useState } from "react";
import { ReactComponent as LeftArrow } from "../../assets/images/images-svg/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/images-svg/rightArrow.svg";
import ClientImg from "../../assets/images/images-svg/client-large.svg";
import Review from "../../assets/images/images-png/Export.png";
import Download from "../../assets/images/images-svg/Download.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import moment from "moment";
import { FormRequestStatus } from "../../constants/session";
import { ISession } from "../../types/session";
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from "../settings/business-settings/BusinessForm";
import CloseIcon from "@mui/icons-material/Close";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import { getFileType } from "../../utils/FileUtils";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Close } from "@mui/icons-material";
import { setResponseValue } from "../../redux/features/api-response/api-response";
import { sessionService } from "../../service/session.service";

const SessionForms = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDesktop, setDesktop] = useState<number>(4); // Number of visible slides
  const [activeIndex, setActiveIndex] = useState<number>(0); // Starting index
  const [open, setOpen] = useState<boolean>(false);
  const [previewFormResult, setPreviewFormResult] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>();
  const [survey, setSurvey] = useState<any>(null);
  const [confirmationPopUp, setConfirmationPopUp] = useState<boolean>(false);
  const [agreedPolicy, setAgreedPolicy] = useState<boolean>(false);
  const [selectedFormRequestId, setSelectedFormRequestId] = useState<string[]>(
    []
  );
  const { currentSession } = useAppSelector((state) => state.sessionSlice);

  // Set the number of slides visible based on screen size (adaptive behavior)
  useEffect(() => {
    const updateScreenWidth = () => {
      if (window.innerWidth > 1250) {
        setDesktop(4);
      } else if (window.innerWidth > 991) {
        setDesktop(3);
      } else if (window.innerWidth > 650) {
        setDesktop(2);
      } else {
        setDesktop(1);
      }
    };
    updateScreenWidth();
    window.addEventListener("resize", updateScreenWidth);

    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  // Left scroll button logic
  const handleLeft = () => {
    if (activeIndex > 0) {
      setActiveIndex((prevIndex) => Math.max(prevIndex - isDesktop, 0));
    }
  };

  // Right scroll button logic
  const handleRight = () => {
    if (!currentSession) {
      return;
    }
    if (activeIndex + isDesktop < currentSession?.requiredForms.length) {
      setActiveIndex((prevIndex) =>
        Math.min(
          prevIndex + isDesktop,
          currentSession?.requiredForms.length - 1
        )
      );
    }
  };

  const handleAll = () => {
    if (
      currentSession?.formRequests &&
      selectedFormRequestId.length !== currentSession?.formRequests.length
    ) {
      setSelectedFormRequestId(
        currentSession?.formRequests.map((item) => item.id)
      );
    } else {
      setSelectedFormRequestId([]);
    }
  };
  const handleSelect = (formId: string) => {
    const isExist = selectedFormRequestId.some((item) => item === formId);
    if (isExist) {
      setSelectedFormRequestId(
        selectedFormRequestId.filter((item) => item !== formId)
      );
    } else {
      setSelectedFormRequestId([...selectedFormRequestId, formId]);
    }
  };

  const showArrow =
    currentSession && isDesktop < currentSession?.requiredForms?.length;
  const triggerDownload = (url: string) => {
    // Create a temporary invisible anchor element
    const link = document.createElement("a");
    link.href = url;

    // Append it to the body, trigger click, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up the URL object after download
    URL.revokeObjectURL(url);
  };

  const handleExportFormsZip = async () => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await sessionService.createSessionFormsZip(
        selectedFormRequestId
      );
      if (data) {
        triggerDownload(data);
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));

      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  return (
    <Box className="sessionForms">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={currentSession ? currentSession?.requiredForms?.length : 0}
        visibleSlides={isDesktop}
        step={isDesktop}
        // currentSlide={activeIndex}
        orientation="horizontal"
        className="cardSlider"
      >
        <Slider className="slider">
          {currentSession?.requiredForms.map((item, i) => (
            <Slide index={i} key={item.id} className="slide">
              <Grid item xs={12} style={{ margin: "10px" }} key={i}>
                <div className="documentCard">
                  <div className="documentCard__img">
                    <div className="documentCard__img-card">
                      <p>{item.formCategory}</p>
                    </div>
                  </div>
                  <div className="documentCard__btn">
                    <span
                      style={{ backgroundColor: "#096dd9" }}
                      onClick={() => {
                        const surveydata = new Model(item.formSchema);
                        surveydata.showCompleteButton = false;
                        setSurvey(surveydata);
                        setOpen(true);
                      }}
                    >
                      Preview
                    </span>
                  </div>
                </div>
              </Grid>
            </Slide>
          ))}
        </Slider>
        {showArrow && (
          <ButtonBack className="sliderNavBtn" onClick={handleLeft}>
            <LeftArrow />
          </ButtonBack>
        )}
        {showArrow && (
          <ButtonNext className="sliderNavBtn" onClick={handleRight}>
            <RightArrow />
          </ButtonNext>
        )}
      </CarouselProvider>
      <Box className="session-Request">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className="formRequest-title">
            Request & Submission
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              if (selectedFormRequestId.length) {
                setConfirmationPopUp(true);
              }
            }}
          >
            Export
          </Button>
        </div>

        <TableContainer component={Paper} className="tableResponsive">
          <StyledTable
            sx={{
              minWidth: 700,
              borderCollapse: "separate",
              cursor: "pointer",
              borderSpacing: "0 8px",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Checkbox
                    checked={
                      selectedFormRequestId.length ===
                      currentSession?.formRequests.length
                    }
                    onChange={() => handleAll()}
                  />{" "}
                  All
                </StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Person</StyledTableCell>
                <StyledTableCell>Form</StyledTableCell>
                <StyledTableCell>Send Date</StyledTableCell>
                <StyledTableCell>Submit Date</StyledTableCell>
                <StyledTableCell>Review Date</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Data Review</StyledTableCell>
                <StyledTableCell>Result</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentSession?.formRequests &&
                currentSession?.formRequests.map((item, index) => (
                  <StyledTableRow
                    key={index}
                    onClick={() => {
                      if (item.status !== FormRequestStatus.Sent)
                        navigate(`/form-review/${item.id}`);
                    }}
                  >
                    <StyledTableCell>
                      <Checkbox
                        checked={selectedFormRequestId.includes(item.id)}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelect(item.id);
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {item?.entityType}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box className="flex">
                        <span className="userMapCart__img">
                          <img
                            src={item.profileUrl || ClientImg}
                            className="userSmallCircle"
                            alt={""}
                          />
                        </span>
                        <Typography className="ClientName">
                          {item.entityName}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {item?.formName}
                    </StyledTableCell>
                    {/* sent Date */}
                    <StyledTableCell>
                      {moment(item.sentDtm).format("ll")}
                    </StyledTableCell>
                    {/* Submit date */}

                    <StyledTableCell>
                      {item.submitDtm ? (
                        <Typography>
                          {moment(item.submitDtm).format("ll")}
                        </Typography>
                      ) : (
                        <Box className="dateMessage">Not Submitted Yet</Box>
                      )}
                    </StyledTableCell>
                    {/* review Date */}
                    <StyledTableCell>
                      {item?.reviewDtm ? (
                        moment(item?.reviewDtm).format("ll")
                      ) : (
                        <Box className="dateMessage">Not Sign Yet</Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{item.status}</StyledTableCell>
                    <StyledTableCell>
                      <Box
                        className="flex"
                        onClick={() => {
                          if (item.status !== FormRequestStatus.Sent)
                            navigate(`/form-review/${item.id}`);
                        }}
                      >
                        <img src={Review} alt="" />
                        {(item.status === FormRequestStatus.Complete ||
                          item.status === FormRequestStatus.InReview ||
                          item.status === FormRequestStatus.Submitted) && (
                          <Typography>
                            {item.reviewData?.checkList?.length || 0} /{" "}
                            {item.checkListCount ||
                              item.form?.checkList?.length}{" "}
                            reviewed
                          </Typography>
                        )}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      {/* download */}
                      {item.pdfUrl && (
                        <div className="flex">
                          <a
                            href={item?.pdfUrl}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <img src={Download} alt="" />
                          </a>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              setPdfUrl(item.pdfUrl);
                              setPreviewFormResult(true);
                            }}
                          >
                            Preview
                          </Button>
                        </div>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Box>
      {open && (
        <Dialog open={open} className="smallModel--intakeforms session-preview">
          <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
            <Typography variant="h3" className="smallModel__title">
              preview
            </Typography>

            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </Button>
          </Box>

          <div>
            <Survey id="id" model={survey} />;
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              margin: "12px",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </div>
        </Dialog>
      )}
      {previewFormResult && (
        <Dialog
          open={previewFormResult}
          className="smallModel--intakeforms session-preview"
        >
          <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
            <Typography variant="h3" className="smallModel__title">
              preview
            </Typography>

            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setPreviewFormResult(false);
              }}
            >
              <CloseIcon />
            </Button>
          </Box>

          <div style={{ height: "600px", overflow: "auto" }}>
            {pdfUrl && (
              <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={[
                  {
                    uri: pdfUrl,
                    fileType: getFileType(pdfUrl),
                  },
                ]}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: false,
                  },
                }}
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              margin: "12px",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setPreviewFormResult(false);
              }}
            >
              Close
            </Button>
          </div>
        </Dialog>
      )}
      <Dialog open={confirmationPopUp} className="smallModel confirmExport">
        <div>
          <Box className="smallModel__head" style={{ padding: "8px 16px" }}>
            <Typography variant="h3" className="smallModel__title">
              Confirmation
            </Typography>
            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setConfirmationPopUp(false);
                setAgreedPolicy(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <DialogContent>
            <Typography>
              Check here to confirm you have acknowledged HIPPA policy for
              managing healthcare data properly.
            </Typography>

            <span className="agree-checkBox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreedPolicy}
                    onChange={() => setAgreedPolicy(!agreedPolicy)}
                  />
                }
                label="I Agree."
              />
            </span>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={!agreedPolicy}
              variant="contained"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                setConfirmationPopUp(false);
                handleExportFormsZip();
              }}
            >
              Export
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Box>
  );
};

export default SessionForms;
