import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Table,
  TableCell,
  tableCellClasses,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import moment from "moment";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  InfoWindow,
  Libraries,
} from "@react-google-maps/api";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setResponseValue } from "../../../redux/features/api-response/api-response";
import { setRefreshValue } from "../../../redux/features/schedules-main/match-slice";
import { clearFilterValues } from "../../../redux/features/providers-main/provider-filter-slice";
import {
  GetProviderById,
  dataUpdated,
} from "../../../redux/features/providers-main/provider-list-slice";
import { setSchedulesFilterValues } from "../../../redux/features/schedules-main/schedules-filter-slice";
import { setSearchValue } from "../../../redux/features/active-steps/active-slice";
import { GetCalenderList } from "../../../redux/features/schedules-main/booking-scheduler-slice";
import { getAllBookingsData } from "../../../redux/features/schedules-main/schedule-list-slice";

import MatchService from "../../../service/match.service";

import ScheduleFilter from "../../filter-main/ScheduleFilter";
import ScheduleMatchList from "./ScheduleMatchList";
import Loader from "../../../layouts/loader/Loader";

import { getBusinessUnitName } from "../../../utils/EntityUtils";
import { filterMatchOn } from "../../../helpers/schedule-matchon-filter-helper/matchon-filter-helper";

import {
  MatchedProvider,
  MatchResult,
  ScheduleFilters,
  BaseProvider,
  BaseClient,
} from "../../../types/schedule";
import { ClientForms } from "../../../types/client";
import { ICenter, IMarkerData } from "../../../types/Common";
import { defaultUtilization } from "../../../constants/availability";
import { ProviderForms } from "../../../types/provider";

import { getBussinessName } from "../../../constants/common";
import { WeekDaysFullName } from "../../../constants/schedule";

import ProviderImgVertical from "../../../assets/images/images-svg/provider-large-vertical.svg";
import ClientImgVertical from "../../../assets/images/images-svg/client-large.svg";
import ClientMarker from "../../../assets/images/images-png/pin-green.png";
import ProviderMarker from "../../../assets/images/images-png/pin-blue.png";
import ClientHighlightMarker from "../../../assets/images/images-png/pin-red.png";
import ProviderHighlightMarker from "../../../assets/images/images-png/pin-red.png";
import { ReactComponent as FilterIcon } from "../../../assets/images/images-svg/filterIcon.svg";

import "./schedulesMatch.scss";

interface Props {
  clientData: ClientForms;
  clientName: string;
  setCounter: (val: number) => void;
  setSelectedProviders: React.Dispatch<React.SetStateAction<MatchedProvider[]>>;
  selectedProviders: ProviderForms[];
  setSelectedProviderIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedProviderIds: string[];
  isDataEdited: boolean;
  setLastBookedDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  entityType: string;
  selectedClients: ClientForms[];
}

const libraries: Libraries | undefined = ["places"]; // Define your required libraries here

// Custom Table Col style
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    // textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
  // textAlign: "center",
}));

// Custom Table Row style

// Custom Table
const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

const SchedulesMatch: React.FC<Props> = ({
  clientName,
  isDataEdited,
  setCounter,
  setSelectedProviderIds,
  setSelectedProviders,
  setLastBookedDate,
  entityType,
  selectedClients,
}) => {
  // #region variables region
  //dispatch for redux
  const dispatch = useAppDispatch();

  //id retrieved from the url to fetch a single client
  const { id } = useParams();

  //for loader component
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //stores selected client Data
  const selectedClientData = useAppSelector(
    (state) => state.clientListSlice.singleClient
  );

  const [center, setCenter] = useState<ICenter>({
    lat: 0,
    lng: 0,
  });
  //list data for providers
  const [listData, setListData] = useState<MatchedProvider[]>([]);

  //filtered MatchResults
  const [filteredMatchResults, setFilteredMatchResults] = useState<
    MatchResult[]
  >([]);

  //filtered MatchResults
  const [matchResults, setMatchResults] = useState<MatchResult[]>([]);

  const [matchedProvider, setMatchedProvider] = useState<BaseProvider[]>([]);

  const [matchClient, setMatchClient] = useState<BaseClient[]>([]);
  //markers data
  const [markersData, setMarkersData] = useState<IMarkerData[]>([]);

  //stores selected provider
  const [selectedProvider, setSelectedProvider] = useState<BaseProvider>();

  //store selected clients
  const [selectedClient, setSelectedClient] = useState<BaseClient>();

  //total count for clients/providers
  const [totalCount, setTotalCount] = useState<number>(0);

  const [checkStartDate, setCheckStartDate] = useState<Date>();
  const [checkEndDate, setCheckEndDate] = useState<Date>();

  //slot base clients/providers count
  const [baseCount, setBaseCount] = useState<number>(0);

  //remaining Auth Hours
  const [remainingAuthHours, setRemainingAuthHours] = useState<number>(0);

  //Day filter
  const [selectedFilterDay, setSelectedFilterDay] = useState<string[]>([]);

  //for updating newData
  const [isScheduleBooked, setIsScheduledBooked] = useState<boolean>(false);

  //match values
  const matchValues = useAppSelector((state) => state.matchSlice);

  const [markerMap, setMarkerMap] = useState<{
    [key: string]: google.maps.Marker;
  }>({});

  //ClientInsurance
  const clientInsurance = useAppSelector(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  //business units
  const businessUnits = useAppSelector(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //controls filter modal visibility
  const [filterModal, setFilterModal] = useState<boolean>(false);

  //stores provider data after filter
  const [filteredData, setFilteredData] = useState<MatchedProvider[]>([]);

  //stores selected match details
  const [selectedMatchResult, setSelectedMatchResult] = useState<string[]>([]);

  //search value from the redux
  const searchValue = useAppSelector((state) => state.activeStep.searchValue);

  //filter values from redux
  const filterTypes = useAppSelector((state) => state.schedulesFilterSlice);

  //currentProvider for match clients
  const currentProvider = useAppSelector(
    (state) => state.providerListSlice.singleProvider
  );

  // #endregion

  // #region methods region

  //handles closing of single filter
  const handleCloseSingleFilter = (key: string, item: string) => {
    if (key === "insuranceProvider") {
      let value = filterTypes.insuranceProvider.filter((i) => i !== item);
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, insuranceProvider: value })
      );
    } else if (key === "businessUnit") {
      let value = filterTypes.businessUnit.filter((i) => i !== item);
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, businessUnit: value })
      );
    } else if (key === "clientStatus") {
      let value = filterTypes.clientStatus.filter((i) => i !== item);
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, clientStatus: value })
      );
    } else if (key === "providerStatus") {
      let value = filterTypes.providerStatus.filter((i) => i !== item);
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, providerStatus: value })
      );
    } else if (key === "intakeStage") {
      let value = filterTypes.intakeStage.filter((i) => i !== item);
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, intakeStage: value })
      );
    } else if (key === "onBoardingStage") {
      let value = filterTypes.onBoardingStage.filter((i) => i !== item);
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, onBoardingStage: value })
      );
    } else if (key === "ScheduleStatus") {
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, ScheduleStatus: "" })
      );
    } else if (key === "startDate") {
      dispatch(setSchedulesFilterValues({ ...filterTypes, startDate: null }));
      dispatch(dataUpdated({ dataUpdate: false }));
    } else if (key === "endDate") {
      dispatch(setSchedulesFilterValues({ ...filterTypes, endDate: null }));
      dispatch(dataUpdated({ dataUpdate: false }));
    } else if (key === "age") {
      dispatch(setSchedulesFilterValues({ ...filterTypes, age: null }));
    }
  };

  //return the Insurance Name
  const getInsuranceName = (insuranceId: string): string => {
    const val = clientInsurance.find((item) => item.id === insuranceId);
    if (val) {
      return val.insuranceProviderName;
    }
    return "";
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (
    marker: google.maps.Marker,
    place: IMarkerData
  ) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.id]: marker };
    });
  };

  const [selectedPlace, setSelectedPlace] = useState<IMarkerData>({
    entityType: "",
    fullAddress: "",
    id: "",
    name: "",
    pos: {
      lat: 0,
      lng: 0,
    },
    utilization: defaultUtilization,
  });
  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  const markerClickHandler = (place: IMarkerData) => {
    let setFlag = true;

    while (setFlag) {
      if (entityType === "Provider") {
        for (let i = 0; i < matchClient.length; i++) {
          if (matchClient[i].clientBasicDetails.id === place.id) {
            setSelectedClient(matchClient[i]);
            setFlag = false;
          }
        }
      } else {
        for (let i = 0; i < matchedProvider.length; i++) {
          if (matchedProvider[i].employmentDetails.id === place.id) {
            setSelectedProvider(matchedProvider[i]);
            setFlag = false;
          }
        }
      }
      setFlag = false;
    }
    // Remember which place was clicked
    setSelectedPlace(place);
    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }
    setTimeout(() => {
      setInfoOpen(true);
    }, 0);

    // if you want to center the selected Marker
    // setCenter(place.pos);
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBltIfej576bf5xww8OmmdFT93sw45a5mE",
    libraries: libraries, //googleMapLibraries, //must have here!!! since map will be loaded first.
  });
  const renderMap = () => {
    return (
      <GoogleMap
        center={center}
        zoom={14}
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        options={{
          styles: [
            {
              featureType: "landscape",
              elementType: "all",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "water",
              elementType: "all",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
      >
        {markersData?.map((place, index) => (
          <Marker
            key={index}
            position={place.pos}
            icon={{
              url:
                place.entityType === "client"
                  ? place.id === selectedPlace.id
                    ? ClientHighlightMarker
                    : ClientMarker
                  : place.id === selectedPlace.id
                  ? ProviderHighlightMarker
                  : ProviderMarker,
              scaledSize:
                place.id === selectedPlace.id
                  ? new google.maps.Size(70, 70)
                  : new google.maps.Size(50, 50),
            }}
            onLoad={(marker: google.maps.Marker) => {
              return markerLoadHandler(marker, place);
            }}
            onMouseOver={() => markerClickHandler(place)}
            onMouseOut={() => setInfoOpen(false)}
          />
        ))}
        {infoOpen && selectedPlace && (
          <InfoWindow
            anchor={markerMap[selectedPlace.id]}
            onCloseClick={() => setInfoOpen(false)}
          >
            <div>
              <h3>{selectedPlace.name}</h3>
              <div>{selectedPlace.fullAddress}</div>
              <br></br>
              <div>
                Avg Wk Booked:&emsp;&emsp;
                {selectedPlace.utilization?.weeklyAvgBooked.toFixed(2)}
              </div>
              <div>
                Avg Wk Available:&ensp;&ensp;&nbsp;
                {selectedPlace.utilization?.weeklyAvgAvailability.toFixed(2)}
              </div>
              <div>
                Avg Wk Remaining:&ensp;
                {selectedPlace.utilization?.weeklyAvgRemainedByAvailability.toFixed(
                  2
                )}
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  };

  // #endregion

  // #region useEffect region
  const mapBaseClientToClientForms = (baseClient: BaseClient): ClientForms => {
    return {
      clientBasicDetails: baseClient.clientBasicDetails,
      clientContactDetails: baseClient.clientContactDetails,
      availabilityDetails: baseClient.availabilityDetails, // Assign appropriate values
      isEditClient: false, // Assign appropriate value
      clientInsurance: [], // Assign appropriate values
      authorizations: baseClient.authorizations,
      clientFiles: [], // Assign appropriate values
      clientMessages: [], // Assign appropriate values
      bookedServices: [], // Assign appropriate values
      clientUtilization: baseClient.clientUtilization,
      clientAuthUtilization: baseClient.clientUtilization, // Adjust if different
      schedules: [], // Assign appropriate values
    };
  };
  const mapProviderFormsToMatchedProvider = (
    provider: ProviderForms
  ): MatchedProvider => {
    return {
      matchOn: [], // populate this with appropriate values if available
      distance: "", // populate this with appropriate values if available
      score: 0, // populate this with appropriate values if available
      slotSummary: "", // populate this with appropriate values if available
      employmentDetails: provider.employmentDetails,
      availabilityDetails: provider.availabilityDetails,
      providerContact: provider.contactDetails,
      providerUtilization: provider.providerUtilization,
    };
  };

  const getProvidersByMatchValues = async () => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));

      const { data } = await MatchService.AutoPlanForClient(id!, matchValues);

      if (data) {
        setIsLoading(true);
        let tempData: MatchResult[] = [];
        data.weekDayResults.map((result) => {
          const baseProvider = data.baseProviders.find(
            (item) => item.employmentDetails.id === result.providerId
          );

          const obj = {
            providerName:
              baseProvider?.employmentDetails.firstName! +
              " " +
              baseProvider?.employmentDetails.lastName!,
            clientName:clientName,

            dayOfWeek: result.dayOfWeek,
            weeks: result.weeks,
            dates: result.dates,
            time: result.time,
            slotSummary: result.slotSummary,
            distance: baseProvider?.distance!,
            matchOn: baseProvider?.matchOn!,
            score: baseProvider?.score!,

            baseProvider: baseProvider!,
            baseClient: null,

            //get authorizations
            authCode: result.authCode,
            remainingAuthHours: data.remainingAuthHours,
            authorizations: result.authorizations,

            //get provider utilization
            weeklyAvgRemainedByAvailabilityForProvider:
              baseProvider?.providerUtilization
                ?.weeklyAvgRemainedByAvailability!,
            weeklyAvgBookedForProvider:
              baseProvider?.providerUtilization?.weeklyAvgBooked!,
            utilizationPcntByAvailabilityForProvider:
              baseProvider?.providerUtilization?.utilizationPcntByAvailability!,

            //get client utilization
            weeklyAvgRemainedAuthForClient:
              data?.clientUtilization?.weeklyAvgRemainedByAuthorization!,
            weeklyAvgBookedForClient: data?.clientUtilization?.weeklyAvgBooked!,
            utilizationPcntByAuthorizationForClient:
              data?.clientUtilization?.utilizationPcntByAuthorization!,
          };
          // Push object to listData array
          if (baseProvider !== undefined) {
            matchedProvider.push(baseProvider);
          }
          tempData.push(obj);
        });

        //sort by score desc, weeks desc
        const sortedData = tempData.sort(
          (a, b) =>
            b.weeks - a.weeks || //prioritize on weeks
            b.score - a.score || //then on score
            b.weeklyAvgRemainedByAvailabilityForProvider -
              a.weeklyAvgRemainedByAvailabilityForProvider //then on provider availability
        );
        // console.log("sortedData:", sortedData);
        setMatchResults(() => sortedData);
        setTotalCount(data?.totalProvidersCount);
        setBaseCount(data?.baseProvidersCount);
        setRemainingAuthHours(data?.remainingAuthHours);
        setCheckStartDate(data?.checkStartDate);
        setCheckEndDate(data?.checkEndDate);
        setListData(data.baseProviders);
        setFilteredMatchResults(sortedData);
      }
    } catch (e: any) {
      console.log(e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
      setIsScheduledBooked(false);
    }
  };

  const getClientsByMatchValues = async () => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await MatchService.AutoPlanForProvider(id!, matchValues);
      if (data) {
        setIsLoading(true);
        let tempData: MatchResult[] = [];
        data.weekDayResults.map((result) => {
          const baseClient = data.baseClients.find(
            (item) => item.clientBasicDetails.id === result.clientId
          );

          const obj = {
            providerName:
              currentProvider.employmentDetails.firstName +
              " " +
              currentProvider.employmentDetails.lastName,
            clientName:
              baseClient?.clientBasicDetails.childFirstName! +
              " " +
              baseClient?.clientBasicDetails.childLastName!,

            dayOfWeek: result.dayOfWeek,
            weeks: result.weeks,
            dates: result.dates,
            time: result.time,
            slotSummary: result.slotSummary,
            distance: baseClient?.distance!,
            matchOn: baseClient?.matchOn!,
            score: baseClient?.score!,

            baseProvider: null,
            baseClient: baseClient!,

            //get authorizations
            authCode: result.authCode,
            remainingAuthHours: result.remainingAuthHours!,
            authorizations: result.authorizations,

            //get client utilization
            weeklyAvgRemainedAuthForClient:
              baseClient?.clientUtilization?.weeklyAvgRemainedByAuthorization!,
            weeklyAvgBookedForClient:
              baseClient?.clientUtilization?.weeklyAvgBooked!,
            utilizationPcntByAuthorizationForClient:
              baseClient?.clientUtilization?.utilizationPcntByAuthorization!,

            //get provider utilization
            weeklyAvgRemainedByAvailabilityForProvider:
              data?.providerUtilization?.weeklyAvgRemainedByAvailability!,
            weeklyAvgBookedForProvider:
              data?.providerUtilization?.weeklyAvgBooked!,
            utilizationPcntByAvailabilityForProvider:
              data?.providerUtilization?.utilizationPcntByAvailability!,
          };
          mapBaseClientToClientForms(baseClient!);
          // Push object to listData array
          if (baseClient !== undefined) {
            matchClient?.push(baseClient);
          }
          tempData.push(obj);
        });

        // sort by score desc, weeks desc
        const sortedData = tempData.sort(
          (a, b) =>
            b.score - a.score ||
            b.weeklyAvgRemainedAuthForClient -
              a.weeklyAvgRemainedAuthForClient ||
            b.weeks - a.weeks
        );
        // console.log("sortedData:", sortedData, tempData);

        setMatchResults(() => sortedData);
        setFilteredMatchResults(sortedData);
        setTotalCount(data?.totalClientsCount);
        setBaseCount(data?.baseClientsCount);
        setRemainingAuthHours(data?.remainingAuthHours);
        setCheckStartDate(data?.checkStartDate);
        setCheckEndDate(data?.checkEndDate);
        setListData([mapProviderFormsToMatchedProvider(currentProvider)]);
      }
    } catch (e: any) {
      // console.log(e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
      setIsScheduledBooked(false);
    }
  };

  const handleShowBooking = () => {
    setCounter(3);
    localStorage.setItem("selectedProviders", JSON.stringify(listData));

    let clientIds: string[] = [];
    let providerIds: string[] = [];
    let selectedProviders: MatchedProvider[] = [];

    if (entityType === "Provider") {
      if (matchResults) {
        matchResults.forEach((result) => {
          selectedMatchResult.forEach((item) => {
            if (
              item ===
              `${result?.baseClient?.clientBasicDetails.id}${result?.dayOfWeek}`
            ) {
              clientIds.push(result?.baseClient?.clientBasicDetails.id!);
              const isExist = selectedClients.some(
                (item) =>
                  item.clientBasicDetails.id ===
                  result?.baseClient?.clientBasicDetails.id!
              );
              if (!isExist) {
                selectedClients.push(
                  mapBaseClientToClientForms(result?.baseClient!)
                );
              }
            }
          });
        });
      }
      providerIds = [currentProvider?.employmentDetails.id];
      selectedProviders = [mapProviderFormsToMatchedProvider(currentProvider)];
    } else {
      clientIds = [selectedClientData?.clientBasicDetails.id];
      if (matchResults) {
        matchResults.forEach((result) => {
          selectedMatchResult.forEach((item) => {
            if (
              item ===
              `${result?.baseProvider?.employmentDetails.id}${result.dayOfWeek}`
            ) {
              providerIds.push(result?.baseProvider?.employmentDetails.id!);
              const isProviderExist = selectedProviders.some(
                (item) =>
                  item.employmentDetails.id ===
                  result?.baseProvider?.employmentDetails.id!
              );
              if (!isProviderExist) {
                selectedProviders.push(result?.baseProvider!);
              }
            }
          });
        });
      }
    }

    setSelectedProviders(selectedProviders);
    setSelectedProviderIds(providerIds);

    dispatch(GetCalenderList({ clientIds, providerIds }));
    dispatch(getAllBookingsData());
  };

  useEffect(() => {
    const markers: IMarkerData[] = [];
    if (entityType === "Provider") {
      if (matchClient && matchClient.length > 0) {
        matchClient.forEach((item, index: number) => {
          if (index === 0) {
            setSelectedClient(matchClient[0]);
          }

          markers.push({
            id: item?.clientBasicDetails?.id,
            entityType: "client",
            pos: {
              lat: item?.clientContactDetails?.address?.lat,
              lng: item?.clientContactDetails?.address?.lng,
            },
            fullAddress: `${item?.clientContactDetails?.address?.fullAddress},
            ${item?.clientContactDetails?.city}, ${item?.clientContactDetails?.state} ${item?.clientContactDetails?.zipCode}`,
            name:
              item?.clientBasicDetails?.childFirstName +
              " " +
              item?.clientBasicDetails?.childLastName,
            utilization: item.clientUtilization,
          });
        });

        setMarkersData([...markersData, ...markers]);
        // console.log("markersData:", markersData?.length, markers?.length);
      }
    } else {
      if (matchedProvider && matchedProvider.length > 0) {
        matchedProvider.forEach((item, index: number) => {
          if (index === 0) {
            setSelectedProvider(matchedProvider[0]);
          }

          markers.push({
            id: item?.employmentDetails?.id,
            // id: `provider${index + 1}`,
            entityType: "provider",
            pos: {
              lat: item?.providerContact?.address?.lat,
              lng: item?.providerContact?.address?.lng,
            },
            fullAddress: `${item?.providerContact?.address?.fullAddress},
            ${item?.providerContact?.city}, ${item?.providerContact?.state} ${item?.providerContact?.zipCode}`,
            name:
              item?.employmentDetails?.firstName +
              " " +
              item.employmentDetails?.lastName,
            utilization: item.providerUtilization,
          });
        });

        setMarkersData([...markersData, ...markers]);
        // console.log("markersData:", markersData?.length, markers?.length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchedProvider.length, matchClient.length]);
  //address refresh from local
  useEffect(() => {
    //clean up
    setMarkersData([]);
    const data: any = localStorage.getItem("MatchValue");
    if (data) {
      const parsedDATA = JSON.parse(data);
      dispatch(setRefreshValue(parsedDATA));
    }
  }, [dispatch]);

  //populate dependent data
  useEffect(() => {
    if (
      (selectedClientData || currentProvider) &&
      matchResults?.length === 0 &&
      (!isLoading || isDataEdited)
    ) {
      // console.log("call 222", listData?.length, isLoading);
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchValues, id, selectedClientData, isDataEdited]);
  //populate clientMarker
  useEffect(() => {
    const mD: IMarkerData[] = [];
    if (entityType === "Provider") {
      setCenter({
        lat: currentProvider?.contactDetails?.address?.lat
          ? currentProvider?.contactDetails?.address?.lat
          : 0,
        lng: currentProvider?.contactDetails?.address?.lng
          ? currentProvider?.contactDetails?.address?.lng
          : 0,
      });
      mD.push({
        id: currentProvider?.contactDetails?.id,
        entityType: "provider",
        pos: {
          lat: currentProvider?.contactDetails?.address?.lat
            ? currentProvider?.contactDetails?.address?.lat
            : 0,
          lng: currentProvider?.contactDetails?.address?.lng
            ? currentProvider?.contactDetails?.address?.lng
            : 0,
        },
        fullAddress: `${currentProvider?.contactDetails?.address?.fullAddress},
        ${currentProvider?.contactDetails?.city}, 
        ${currentProvider?.contactDetails?.state}
        ${currentProvider?.contactDetails?.zipCode}`,
        name:
          currentProvider?.employmentDetails?.firstName +
          " " +
          currentProvider?.employmentDetails?.lastName,
        utilization: currentProvider?.providerUtilization,
      });
    } else {
      setCenter({
        lat: selectedClientData?.clientContactDetails?.address?.lat
          ? selectedClientData?.clientContactDetails?.address?.lat
          : 0,
        lng: selectedClientData?.clientContactDetails?.address?.lng
          ? selectedClientData?.clientContactDetails?.address?.lng
          : 0,
      });
      mD.push({
        id: selectedClientData?.clientBasicDetails?.id,
        entityType: "client",
        pos: {
          lat: selectedClientData?.clientContactDetails?.address?.lat
            ? selectedClientData?.clientContactDetails?.address?.lat
            : 0,
          lng: selectedClientData?.clientContactDetails?.address?.lng
            ? selectedClientData?.clientContactDetails?.address?.lng
            : 0,
        },
        fullAddress: `${selectedClientData?.clientContactDetails?.address?.fullAddress},
        ${selectedClientData?.clientContactDetails?.city}, 
        ${selectedClientData?.clientContactDetails?.state}
        ${selectedClientData?.clientContactDetails?.zipCode}`,
        name:
          selectedClientData?.clientBasicDetails?.childFirstName +
          " " +
          selectedClientData?.clientBasicDetails?.childLastName,
        utilization: selectedClientData?.clientUtilization,
      });
    }

    setMarkersData(mD);
    setSelectedPlace(mD[0]); //set selected as client
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientData, currentProvider]);

  //populate match data
  useEffect(() => {
    if (entityType === "Provider") {
      if (id) {
        dispatch(GetProviderById({ providerId: id }));
      }
    }
  }, []);

  useEffect(() => {
    if ((isLoading && matchResults?.length === 0) || isScheduleBooked) {
      if (entityType === "Provider") {
        getClientsByMatchValues().finally(() => {
          // console.log("refresh");

          setIsLoading(false); // Reset loading indicator once done
          dispatch(clearFilterValues({}));
          dispatch(setSearchValue(""));
        });
      } else {
        getProvidersByMatchValues().finally(() => {
          // console.log("refresh");
          setIsLoading(false); // Reset loading indicator once done
          dispatch(clearFilterValues({}));
          dispatch(setSearchValue(""));
        });
      }
    }
  }, [isLoading, matchResults, listData, isScheduleBooked]);

  //filters
  // useEffect(() => {
  //   if (selectedFilterDay.length > 0) {
  //     const filteredData = filteredMatchResults.filter((item) =>
  //       selectedFilterDay.includes(item.dayOfWeek)
  //     );
  //     setFilteredMatchResults(filteredData);
  //   } else {
  //     setFilteredMatchResults(matchResults);
  //   }
  // }, [selectedFilterDay.length]);

  useEffect(() => {
    if (
      filterTypes.businessUnit.length === 0 &&
      filterTypes.clientStatus.length === 0 &&
      filterTypes.providerStatus.length === 0 &&
      filterTypes.insuranceProvider.length === 0 &&
      filterTypes.concerns.length === 0 &&
      filterTypes.intakeStage.length === 0 &&
      filterTypes.onBoardingStage.length === 0 &&
      filterTypes.availabilityStatus === "" &&
      filterTypes.startDate === null &&
      filterTypes.endDate === null &&
      filterTypes.ScheduleStatus === ""
    ) {
      setFilteredMatchResults(matchResults);
    } else {
      const data = filterMatchOn(
        filterTypes,
        searchValue,
        filteredMatchResults
      );
      setFilteredMatchResults(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTypes]);

  useEffect(() => {
    if (matchResults.length > 0) {
      matchResults.map((item) => {
        if (selectedMatchResult.length !== 10) {
          if (entityType === "Client") {
            selectedMatchResult.push(
              item.baseProvider?.employmentDetails.id! + item.dayOfWeek
            );
          } else {
            selectedMatchResult.push(
              item.baseClient?.clientBasicDetails.id! + item.dayOfWeek
            );
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchResults?.length]);

  // #endregion
  console.log("match results:", listData, matchResults);

  return (
    <div>
      {/* {isPending && <Loader isShow={isPending} />} */}
      <Box className="detailsPageHeader">
        <Grid container spacing={0}>
          <Grid item md={12} xs={12}>
            {entityType === "Provider" ? (
              <Typography variant="h6" className="DetailsPageTitle">
                Employee Match -{" "}
                {currentProvider.employmentDetails.firstName +
                  " " +
                  currentProvider.employmentDetails.lastName}
              </Typography>
            ) : (
              <Typography variant="h6" className="DetailsPageTitle">
                Client Match - {clientName}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3}>
        <Grid item lg={8} md={6} xs={12}>
          <div className="scheduleMap">
            <div className="mapStatus">
              <h6 className="mapStatus__text">
                <img src={ClientMarker} alt={ClientMarker}></img>
                Client
              </h6>
              <h6 className="mapStatus__text">
                <img src={ProviderMarker} alt={ProviderMarker}></img>
                Provider
              </h6>
            </div>
            <div style={{ height: "100vh", width: "100%" }}>
              {isLoaded && renderMap()}
            </div>
          </div>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          {entityType === "Provider" && matchResults[0] ? (
            <Box className="detailsCard">
              <Box className="detailsCard__header">
                <Typography className="detailsCard__title">
                  Client Information
                </Typography>
              </Box>
              <Box className="detailsCard__body">
                <div className="userBasicInfoBox userBasicInfoBox--small">
                  <div className="userBasicInfoBox__img">
                    <img
                      src={
                        selectedClient?.clientBasicDetails.clientProfile?.url ||
                        ClientImgVertical
                      }
                      className="img-fluid"
                      alt="image_not_found"
                      style={{
                        width: "80%",
                        height: "80%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  {/* {.length > 0 && ( */}
                  <div className="userBasicInfoBox__details">
                    <h6 className="userBasicInfoBox__title">
                      {selectedClient?.clientBasicDetails?.childFirstName}{" "}
                      {selectedClient?.clientBasicDetails?.childLastName}
                    </h6>
                    <div className="detailsCard-part">
                      <Typography className="detailsCard-part__title">
                        Client Type
                      </Typography>
                      <Typography className="detailsCard-part__text">
                        {selectedClient?.clientBasicDetails.clientType}
                      </Typography>
                    </div>
                    <div className="detailsCard-part">
                      <Typography className="detailsCard-part__title">
                        Distance
                      </Typography>
                      <Typography className="detailsCard-part__text">
                        {selectedClient?.distance}
                        {" miles"}
                      </Typography>
                    </div>
                    <div className="detailsCard-part">
                      <Typography className="detailsCard-part__title">
                        Utilization (booked / remaining)
                      </Typography>
                      <Typography className="detailsCard-part__text">
                        {selectedClient?.clientUtilization?.weeklyAvgBooked.toFixed(
                          2
                        )}
                        {" / "}
                        {
                          selectedClient?.clientUtilization
                            ?.weeklyAvgRemainedByAuthorization
                        }
                      </Typography>
                    </div>
                    <div className="detailsCard-part">
                      <Typography className="detailsCard-part__title">
                        Address
                      </Typography>
                      <Typography className="detailsCard-part__text">
                        {
                          selectedClient?.clientContactDetails?.address
                            ?.fullAddress
                        }
                      </Typography>
                      <Typography className="detailsCard-part__text">
                        {selectedClient?.clientContactDetails.city}{" "}
                        {selectedClient?.clientContactDetails?.state}{" "}
                        {selectedClient?.clientContactDetails?.zipCode}{" "}
                      </Typography>
                    </div>
                  </div>
                  {/* )} */}
                </div>
              </Box>
            </Box>
          ) : (
            <Box className="detailsCard">
              <Box className="detailsCard__header">
                <Typography className="detailsCard__title">
                  Provider Information
                </Typography>
              </Box>
              <Box className="detailsCard__body">
                <div className="userBasicInfoBox userBasicInfoBox--small">
                  <div className="userBasicInfoBox__img">
                    <img
                      src={
                        // (listData.length > 0 &&
                        selectedProvider?.employmentDetails.providerProfile
                          ?.url ||
                        // )
                        ProviderImgVertical
                      }
                      className="img-fluid"
                      alt="image_not_found"
                    />
                  </div>
                  {/* {.length > 0 && ( */}
                  <div className="userBasicInfoBox__details">
                    <h6 className="userBasicInfoBox__title">
                      {selectedProvider?.employmentDetails?.firstName}{" "}
                      {selectedProvider?.employmentDetails?.lastName}
                    </h6>
                    <div className="detailsCard-part">
                      <Typography className="detailsCard-part__title">
                        Employee Type
                      </Typography>
                      <Typography className="detailsCard-part__text">
                        {selectedProvider?.employmentDetails?.type}
                      </Typography>
                    </div>
                    <div className="detailsCard-part">
                      <Typography className="detailsCard-part__title">
                        Distance
                      </Typography>
                      <Typography className="detailsCard-part__text">
                        {selectedProvider?.distance}
                        {" miles"}
                      </Typography>
                    </div>
                    <div className="detailsCard-part">
                      <Typography className="detailsCard-part__title">
                        Utilization (booked / remaining)
                      </Typography>
                      <Typography className="detailsCard-part__text">
                        {selectedProvider?.providerUtilization?.weeklyAvgBooked.toFixed(
                          2
                        )}
                        {" / "}
                        {
                          selectedProvider?.providerUtilization
                            ?.weeklyAvgRemainedByAvailability
                        }
                      </Typography>
                    </div>

                    <div className="detailsCard-part">
                      <Typography className="detailsCard-part__title">
                        Address
                      </Typography>
                      <Typography className="detailsCard-part__text">
                        {
                          selectedProvider?.providerContact?.address
                            ?.fullAddress
                        }
                      </Typography>
                      <Typography className="detailsCard-part__text">
                        {selectedProvider?.providerContact?.city}{" "}
                        {selectedProvider?.providerContact?.state}{" "}
                        {selectedProvider?.providerContact?.zipCode}{" "}
                      </Typography>
                    </div>
                  </div>
                  {/* )} */}
                </div>
              </Box>
            </Box>
          )}
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <div className="baseSelected">
            <p className="baseSelected__title">Client Auth:</p>
            <div className="fromGroup-chips">
              {entityType === "Client" && (
                <Card className="fromGroup-chip-active">
                  {matchValues?.clientSelectedAuthorizationName}
                </Card>
              )}
              <Card className="fromGroup-chip-active">
                {"Auth Code: "}
                {matchValues?.clientAuthCode}
              </Card>
              {entityType === "Client" && (
                <Card className="fromGroup-chip-active">
                  {"Auth Remaining Hours: "}
                  {matchValues?.clientAuthCodeRemainingHours} hrs
                </Card>
              )}

              {matchValues.clientAuthorization ? (
                <Card className="fromGroup-chip-active">
                  {matchValues.clientAuthorization}
                </Card>
              ) : null}
            </div>
          </div>
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <div className="baseSelected">
            <p className="baseSelected__title">Look for Booking:</p>
            <div className="fromGroup-chips">
              {matchValues.availability.beginDate ? (
                <Card className="fromGroup-chip-active">
                  {"Availability: "}
                  {moment(matchValues.availability.beginDate).format(
                    "MM/DD/YYYY"
                  )}
                  {" - "}
                  {moment(matchValues.availability.endDate).format(
                    "MM/DD/YYYY"
                  )}
                </Card>
              ) : null}
              <Card className="fromGroup-chip-active">
                {"Session: "} {matchValues?.duration} hours
              </Card>
              {/* <Card className="fromGroup-chip-active">
                {matchValues?.newSessionsPerDay} sessions per day
              </Card> */}

              {matchValues?.selectedAvailability.map((item, index: number) => (
                <>
                  {item ? (
                    <Card className="fromGroup-chip-active" key={index}>
                      {item?.dayOfWeek} ({item.startTime} - {item.endTime})
                    </Card>
                  ) : null}
                </>
              ))}
            </div>
          </div>
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <div className="baseSelected">
            <p className="baseSelected__title">Optional Filters:</p>
            <div className="fromGroup-chips">
              {matchValues.businessUnit ? (
                <Card className="fromGroup-chip-active">
                  {/* use name version */}
                  {matchValues.businessUnitName}
                </Card>
              ) : null}
              {matchValues.providerType.length > 0 ? (
                <Card className="fromGroup-chip-active">
                  {`${matchValues.providerType.join(" , ")}`}
                </Card>
              ) : null}
              {matchValues.specialities.length > 0 ? (
                <Card className="fromGroup-chip-active">
                  {`${matchValues.specialities.join(" , ")}`}
                </Card>
              ) : null}
              {matchValues.providerOnboardingStages.length > 0 ? (
                <Card className="fromGroup-chip-active">
                  {`${matchValues.providerOnboardingStages.join(" , ")}`}
                </Card>
              ) : null}

              {matchValues.insuranceProvider ? (
                <Card className="fromGroup-chip-active">
                  {getInsuranceName(matchValues.insuranceProvider)}
                </Card>
              ) : null}
              {matchValues.providerOnboardingStatus ? (
                <Card className="fromGroup-chip-active">
                  {matchValues.providerOnboardingStatus}
                </Card>
              ) : null}

              {matchValues.miles ? (
                <Card className="fromGroup-chip-active">
                  {"< "}
                  {matchValues.miles}
                  {" Miles"}
                </Card>
              ) : null}
            </div>
          </div>
        </Grid>

        <Grid item lg={12} xs={12} className="listingTable">
          <Box className="select-filters-buttons">
            <div className="baseSelected">
              <p className="baseSelected__title">
                Check Range: {moment.utc(checkStartDate).format("MM/DD/YYYY")}
                {" - "}
                {moment.utc(checkEndDate).format("MM/DD/YYYY")}
              </p>
              <p className="baseSelected__title">
                Total Suggestions: {matchResults?.length}
              </p>
              <p className="baseSelected__title">
                Matched {entityType}s: {baseCount} out of {totalCount}
              </p>
              {/* <p className="baseSelected__title">
                Remaining Auth Hours: {remainingAuthHours} hours
              </p> */}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <Typography>Filters:</Typography>
                <FormControl sx={{ m: 1, width: 150 }} size="small">
                  <InputLabel id="demo-multiple-checkbox-label">Day</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedFilterDay}
                    onChange={(e) => {
                      setSelectedFilterDay(e.target.value as string[]);
                    }}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: 150, width: 150 },
                      },
                    }}
                  >
                    {WeekDaysFullName.map((day) => (
                      <MenuItem key={day} value={day}>
                        <Checkbox
                          checked={selectedFilterDay.indexOf(day) > -1}
                        />
                        <ListItemText primary={day} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div style={{display:"flex",gap:"10px"}}>
                <Button
                  variant="contained"
                  onClick={handleShowBooking}
                  className={
                    // listData?.length === 0
                    //   ? "btn-disabled button button--large"
                    //   : "button button--large"
                    "button "
                  }
                >
                  Show Calender
                </Button>
                <ButtonGroup
                disableElevation
                variant="contained"
                className="filterGroup-btn"
              >
                <Button
                  style={{ borderLeft: "1px solid white" }}
                  startIcon={<FilterIcon />}
                  onClick={() => {
                    // e.preventDefault();
                    setFilterModal(true);
                  }}
                />
              </ButtonGroup>
              </div>
          
            </div>
          </Box>
          <Box className="selected-filtersButtons" textAlign={"right"}>
            {Object.entries(filterTypes)
              .filter(([_, value], idx) => {
                if (value && typeof value === "string") return true;
                if (value?.length > 0 && typeof value === "object") return true;
                return false;
              })
              .map(([key, value], index) => {
                if (typeof value === "object") {
                  return value.map((subItem: string, subIndex: number) => {
                    if (
                      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
                        subItem
                      )
                    ) {
                      return (
                        <Button className="border-button" key={index}>
                          {getBussinessName(subItem, businessUnits)}
                          <span className="closeBtn">
                            <Close
                              fontSize="small"
                              onClick={() => {
                                handleCloseSingleFilter(key, subItem);
                              }}
                            />
                          </span>
                        </Button>
                      );
                    }
                    return (
                      <Button className="border-button" key={subIndex}>
                        {subItem}
                        <span className="closeBtn">
                          <Close
                            fontSize="small"
                            onClick={() => {
                              handleCloseSingleFilter(key, subItem);
                            }}
                          />
                        </span>
                      </Button>
                    );
                  });
                } else {
                  return (
                    <Button className="border-button" key={index}>
                      {key === "businessUnit"
                        ? getBusinessUnitName(businessUnits, value)
                        : key === "age"
                        ? `Age:${value}`
                        : value}
                      <span className="closeBtn">
                        <Close
                          fontSize="small"
                          onClick={() => {
                            handleCloseSingleFilter(key, value);
                          }}
                        />
                      </span>
                    </Button>
                  );
                }
              })}
          </Box>
          {isLoading && <Loader isShow={isLoading} />}
          {/* autoplan table */}
          <ScheduleMatchList
            selectedClientData={
              entityType === "Client" ? selectedClientData : null
            }
            selectedProviderData={
              entityType === "Provider" ? currentProvider : null
            }
            isScheduleBooked={isScheduleBooked}
            setIsScheduledBooked={setIsScheduledBooked}
            setLastBookedDate={setLastBookedDate}
            setSelectedMatchResult={setSelectedMatchResult}
            selectedMatchResult={selectedMatchResult}
            entityType={entityType}
            checkStartDate={checkStartDate}
            checkEndDate={checkEndDate}
            matchResults={matchResults}
          />
          <Dialog open={filterModal} className="largeModel scheduleFilter">
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Filter Schedules
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => setFilterModal(false)}
              >
                <Close />
              </Button>
            </Box>
            <ScheduleFilter
              closeModal={(val: boolean, filterObject: ScheduleFilters) => {
                dispatch(setSchedulesFilterValues({ ...filterObject }));
                setFilterModal(val);
              }}
              filterData={true}
              listData={[]}
              isFilterClient={entityType}
            />
          </Dialog>
        </Grid>
        <Grid item xs={12} className="text-center" mt={"24px"}></Grid>
      </Grid>
    </div>
  );
};

export default SchedulesMatch;
