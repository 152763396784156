import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import ClientImg from "../../assets/images/images-svg/client-large.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import "./sessionFormReview.scss";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import moment from "moment";
import { IReviewForm } from "../../types/forms";
import {
  GetFormRequestByID,
  UpdateReviewComment,
  UpdateSessionFormData,
} from "../../redux/features/session/session-slice";
import { Close } from "@mui/icons-material";

const SessionFormReview = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [reviewData, setReviewData] = useState<IReviewForm | null>(null);
  const [open, setOpen] = useState(false);
  const [reviewComment, setReviewComment] = useState<string>("");
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [previousRoute, setPreviousRoute] = useState<string>("");
  const currentformRequest = useAppSelector(
    (state) => state.sessionSlice.formRequest
  );
  const [survey, setSurvey] = useState<Model>(
    new Model(currentformRequest?.form.formSchema)
  );

  const handleSaveReviewForm = () => {
    if (survey && currentformRequest) {
      dispatch(
        UpdateSessionFormData({
          formId: currentformRequest?.id,
          formData: survey.data,
          reviewData: reviewData,
        })
      )
        .then(() => {
          setIsUpdated(false);
          setOpen(false);
        })
        .catch((error) => {
          console.error("Error saving review form:", error);
        });
    }
  };

  const handleReviewChecklist = (item: string) => {
    if (reviewData) {
      const isExist = reviewData?.checkList?.some(
        (ele: string) => ele === item
      );
      if (isExist) {
        const updatesQuestionReviews = reviewData?.checkList?.filter(
          (ele) => item !== ele
        );
        setReviewData({
          ...reviewData,
          checkList: updatesQuestionReviews || [],
        });
      } else {
        setReviewData({
          ...reviewData,
          checkList: [...(reviewData?.checkList || []), item],
        });
      }
    } else {
      setReviewData({
        reviewComments: [],
        checkList: [item],
      });
    }
  };

  const handleAddreviewComment = () => {
    if (currentformRequest)
      dispatch(
        UpdateReviewComment({
          formId: currentformRequest.id,
          comment: reviewComment,
        })
      );
    setIsUpdated(true);
    setReviewComment("");
  };

  const formatData = () => {
    if (!currentformRequest) {
      return;
    }
    let questions: any[] = [];
    currentformRequest?.form.formSchema.pages.forEach((item) => {
      questions = [...questions, ...item.elements];
    });
    if (currentformRequest?.formData) {
      let SurveyData = new Model(currentformRequest.form.formSchema);
      SurveyData.data = currentformRequest.formData;
      SurveyData.showQuestionNumbers = "off";
      SurveyData.showNavigationButtons = {
        complete: false,
      };
      setSurvey(SurveyData);
    }
    if (currentformRequest?.reviewData) {
      setReviewData(currentformRequest.reviewData);
    }
  };

  const confirmDiscardChanges = () => {
    setOpen(false);
    navigate(previousRoute);
  };

  const hasChangesSaved = () => {
    if (!reviewData?.checkList || !currentformRequest) return false;
    const isChecklistChanged =
      reviewData.checkList.length ===
      currentformRequest.reviewData?.checkList?.length;
    return isChecklistChanged;
  };

  useEffect(() => {
    if (id) {
      dispatch(GetFormRequestByID({ id }));
    }
    const path = localStorage.getItem("previousPath");
    if (path) {
      setPreviousRoute(path);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (currentformRequest) {
      formatData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentformRequest]);

  useEffect(() => {
    if (
      currentformRequest?.reviewData?.reviewComments !== undefined &&
      isUpdated
    ) {
      setReviewData({
        checkList: reviewData?.checkList || [],
        reviewComments: currentformRequest?.reviewData?.reviewComments || [],
      });
      setIsUpdated(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentformRequest?.reviewData?.reviewComments]);

  return (
    <Box className="formReview">
      <Grid className="formReviewHeader" container spacing={1}>
        <Grid item lg={3} md={12}>
          <Box className="firstSection">
            <Box
              className="back-btn"
              onClick={() => {
                if (hasChangesSaved()) {
                  navigate(previousRoute);
                } else {
                 setOpen(true);
                }
              }}
            >
              <KeyboardBackspaceIcon />
              <Typography>Back</Typography>
            </Box>
            <Typography className="formTitle-session">
              Review Data Session
            </Typography>
          </Box>
        </Grid>
        <Grid className="midSection" item lg={6} md={10}>
          <Box className="flex">
            <Typography className="entityName">Client : </Typography>
            <span className="userMapCart__img">
              <img src={ClientImg} className="userSmallCircle" alt={""} />
            </span>
            <Typography className="ClientName">Scarlett Johnsan</Typography>
          </Box>
          <Box className="flex">
            <Typography className="entityName">Provider : </Typography>
            <span className="userMapCart__img">
              <img src={ClientImg} className="userSmallCircle" alt={""} />
            </span>
            <Typography className="ClientName">Scarlett Johnsan</Typography>
          </Box>
        </Grid>
        <Grid className="lastSection" item lg={3} md={2}>
          <Button
            variant="contained"
            onClick={() => handleSaveReviewForm()}
            sx={{ height: "45px" }}
          >
            Save Review
          </Button>
        </Grid>
      </Grid>
      <Box className="formReviewDetails">
        <Grid container columnSpacing={3}>
          <Grid item sm={12} md={3} lg={3}>
            <Box className="question-header2">
              <Typography>Checklist</Typography>
            </Box>
            <Box className="questionLists">
              {currentformRequest?.form?.checkList &&
                currentformRequest?.form?.checkList.map(
                  (item: any, index: number) => (
                    <Box key={index} className={"customCheckBox"}>
                      <input
                        checked={reviewData?.checkList?.some(
                          (ele: string) => ele === item
                        )}
                        type="checkbox"
                        onClick={() => {
                          handleReviewChecklist(item);
                        }}
                      />
                      <label>{item}</label>
                    </Box>
                  )
                )}
            </Box>
          </Grid>
          <Grid item sm={12} md={5} lg={6} className="formDetails">
            <Box className="reviewQuestionset">
              {survey && (
                <>
                  <Typography className="formName">
                    {currentformRequest?.form.name}
                  </Typography>
                  <Box bgcolor={"#f3f3f3"} className="review-survey">
                    <Survey model={survey} />
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3}>
            <Typography className="formName">Review Comments</Typography>

            <Box className="reviewComments">
              {currentformRequest?.reviewData?.reviewComments &&
                currentformRequest?.reviewData?.reviewComments.map(
                  (item, index) => (
                    <Box className="comment" key={index}>
                      <img
                        src={item.fromUser?.profilePictureUrl || ClientImg}
                        className="userSmallCircle"
                        alt={""}
                      />
                      <Box className="userInfo">
                        <Box className="userInfo__head">
                          <Typography className="ClientName">
                            {item.fromUser.firstName} {item.fromUser.lastName}
                          </Typography>
                          <Typography className="commentTime">
                            {moment(item.createdAt).calendar()}
                          </Typography>
                        </Box>
                        <Typography className="comment-text">
                          {item.comment}
                        </Typography>
                      </Box>
                    </Box>
                  )
                )}
            </Box>

            <Box className="CommentEntryBox">
              <TextField
                type="text"
                placeholder="Leave an internal message..."
                value={reviewComment}
                onChange={(e) => setReviewComment(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAddreviewComment();
                  }
                }}
              />

              <Button variant="contained" onClick={handleAddreviewComment}>
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={open} className="checklistModal">
        <div>
          <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
            <Typography variant="h3" className="smallModel__title">
              Unsaved Changes
            </Typography>
            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <DialogContent>
            <Typography
              style={{ fontSize: "18px", padding: "15px", textAlign: "center" }}
            >
              You have unsaved changes. Are you sure you want to leave without
              saving?
            </Typography>
          </DialogContent>
          <DialogActions
            style={{
              boxShadow: "inset 0px 1px 0px #f0f0f0",
              justifyContent: "space-between",
            }}
          >
            <Button
              className="button"
              variant="contained"
              onClick={() => confirmDiscardChanges()}
            >
              Cancel
            </Button>
            <Button
              className="button"
              variant="contained"
              onClick={() => {
                setOpen(false);
                handleSaveReviewForm()
                navigate(previousRoute)
              }}
            >
              Save Changes
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Box>
  );
};

export default SessionFormReview;
