export enum SyncEventType {
  ClientCreated = "Client.Created",
  ClientUpdated = "Client.Updated",
  ProviderCreated = "Provider.Created",
  ProviderUpdated = "Provider.Updated",
  ClientContactUpdated = "ClientContact.Updated",
  ProviderContactUpdated = "ProviderContact.Updated",
  ClientAuthorizationCreated = "ClientAuthorization.Created",

  //Release 2
  ClientAuthorizationUpdated = "ClientAuthorization.Updated",
}
