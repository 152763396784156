import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sessionService } from "../../../service/session.service";
import { setResponseValue } from "../api-response/api-response";
import { IFormRequest } from "../../../types/forms";
import { ISession, sessionSetting } from "../../../types/session";
import FormService from "../../../service/form.service";

export interface ISessionSlice {
  allSessions: ISession[];
  currentSession: ISession | null;
  formRequest: IFormRequest | null;
  sessionSetting: sessionSetting;
}

const initialState: ISessionSlice = {
  allSessions: [],
  currentSession: null,
  formRequest: null,
  sessionSetting: {
    requireCompletionAtTheBegningOfSession: false,
    requireCompletionAtTheEndOfSession: false,
  }
};

const sessionSlice = createSlice({
  name: "sessionSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetAllSession.fulfilled, (state, action) => {
      state.allSessions = action.payload;
    });
    builder.addCase(GetSessionById.fulfilled, (state, action) => {
      return {
        ...state,
        currentSession: action.payload,
      };
    });
    builder.addCase(UpdateSessionFormData.fulfilled, (state, action) => {
      if (state.currentSession && state.currentSession.formRequests) {
        state.currentSession.formRequests =
          state.currentSession.formRequests.map((item) => {
            if (item.id === action.payload?.id) {
              return {
                ...item,
                formData: action.payload.formData,
                reviewData: action.payload.reviewData,
                status: action.payload.status,
                pdfUrl: action.payload?.pdfUrl,
                reviewDtm: action.payload?.reviewDtm,
              };
            } else {
              return item;
            }
          });
      }
      if (state.formRequest && state.formRequest.reviewData) {
        state.formRequest.reviewData = action.payload.reviewData;
      }
    });

    builder.addCase(UpdateReviewComment.fulfilled, (state, action) => {
      if (state.formRequest) {
        state.formRequest.reviewData = {
          ...state.formRequest.reviewData,
          reviewComments: action.payload?.reviewData?.reviewComments,
          checkList: action.payload?.reviewData?.checkList || [], // Ensure it's never undefined
        };
      }
    });
    builder.addCase(GetFormRequestByID.fulfilled, (state, action) => {
      state.formRequest = action.payload;
    });
    builder.addCase(GetSessionSetting.fulfilled, (state, action) => {
      state.sessionSetting = action.payload;
    });
    builder.addCase(UpdateSessionSettings.fulfilled, (state, action) => {
      state.sessionSetting = action.payload;
    });
  },
});

export const GetAllSession = createAsyncThunk(
  "/getAllSession",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await sessionService.getAllSessions();
      if (data) {
        return data;
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: error?.message || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//get session by Id
export const GetSessionById = createAsyncThunk(
  "/getSessionById",
  async ({ id }: { id: string }, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await sessionService.getSessionById(id);
      return data;
    } catch (e: any) {
      throw dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//get form request by Id
export const GetFormRequestByID = createAsyncThunk(
  "/getFormRequestById",
  async (
    {
      id,
    }: {
      id: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await FormService.getFormRequestById(id);

      if (data) {
        return data;
      }
    } catch (error: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: error.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//updates review data and form data after review
export const UpdateSessionFormData = createAsyncThunk(
  "/updateSessionFormData",
  async (
    {
      formId,
      reviewData,
      formData,
    }: {
      formId: string;
      formData: any;
      reviewData: any;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await sessionService.updateSessionFormData(
        formId,
        { formData, reviewData }
      );

      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (error: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: error.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//updates review comments for session formRequest
export const UpdateReviewComment = createAsyncThunk(
  "/update/reviewComment",
  async (
    {
      formId,
      comment,
    }: {
      formId: string;
      comment: string;
    },
    { dispatch }
  ) => {
    try {
      const { data } = await sessionService.UpdateReviewComment(formId, {
        comment,
      });

      if (data) {
        return data;
      }
    } catch (error: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: error.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//get session setting
export const GetSessionSetting = createAsyncThunk(
  "/getSessionSettings",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await sessionService.getSessionSettings();
      if (data) {
        return data;
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: error?.message || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//update session settings
export const UpdateSessionSettings = createAsyncThunk(
  "/updateSessionSettings",
  async (
    {
      requireCompletionAtTheEndOfSession,
      requireCompletionAtTheBegningOfSession,
    }: {
      requireCompletionAtTheEndOfSession: boolean;
      requireCompletionAtTheBegningOfSession: boolean;
    },
    { dispatch }
  ) => {
    try {
      const payload = {
        requireCompletionAtTheEndOfSession: requireCompletionAtTheEndOfSession,
        requireCompletionAtTheBegningOfSession:
          requireCompletionAtTheBegningOfSession,
      };
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await sessionService.updateSessionSettings(
        payload
      );
      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: error?.message || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export default sessionSlice;
