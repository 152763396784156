import { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import SplitterLayout from "react-splitter-layout";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getClientBoards } from "../../redux/features/client-main/client-board-slice";
import { getAllClientIntakeStage } from "../../redux/features/client-main/client-intake-stage";
import { getProviderBoards } from "../../redux/features/providers-main/provider-board-slice";
import { getAllProviderOnBoardingStages } from "../../redux/features/providers-main/provider-onboardingstage-slice";
import {
  getAllAutomation,
  getAllCustomBoard,
} from "../../redux/features/custom-board/custom-board-slice";

import BoardSidePanel from "./BoardSidePanel";
import BoardPanel from "./BoardPanel";
import { Box } from "@mui/material";

const Board: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState({
    id: "Intake",
    type: "Clients",
  });
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
  const selectedBoardTab = useAppSelector(
    (state) => state.boardSlice.selectedTab
  );
  const dispatch = useAppDispatch();
  const dispatchAction = bindActionCreators(
    {
      getClientBoards,
      getProviderBoards,
      getAllClientIntakeStage,
      getAllProviderOnBoardingStages,
      getAllCustomBoard,
      getAllAutomation,
    },
    dispatch
  );
  //ref for dispatchAction
  const dispatchActionRef = useRef(dispatchAction);

  useEffect(() => {
    dispatchActionRef.current.getAllClientIntakeStage();
    dispatchActionRef.current.getAllProviderOnBoardingStages();
    dispatchActionRef.current.getProviderBoards({ useLoader: false });
    dispatchActionRef.current.getClientBoards({ useLoader: false });
    dispatchActionRef.current.getAllAutomation();
    dispatchActionRef.current.getAllCustomBoard();
  }, []);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const secondaryInitialPercentage = 80;

  useEffect(() => {
    const calculateSecondaryInitialSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const secondaryInitialSizePixels =
          (containerWidth * secondaryInitialPercentage) / 100;

        return secondaryInitialSizePixels;
      }
      return undefined;
    };
    const secondaryInitialSize = calculateSecondaryInitialSize();
    if (secondaryInitialSize !== undefined) {
      initial = secondaryInitialSize;
    }
  }, []);

  let initial: number = (window.innerWidth * 85) / 100;
  let priWidth: number = (window.innerWidth * 0.5) / 100;
  let secWidth: number = (window.innerWidth * 77) / 100;

  const setSplitterData = (value: any) => {
    initial = (value * 85) / 100;
    priWidth = (value * 0.5) / 100;
    secWidth = (value * 77) / 100;
  };
  useEffect(() => {
    window.addEventListener(
      "resize",
      function (event: any) {
        setSplitterData(event.target?.window?.innerWidth);
      },
      true
    );
  }, []);

  useEffect(() => {
    if (selectedBoardTab) {
      // setTimeout(() => {
        const [boardTemplate] = document.getElementsByClassName("boardTemplate");
        boardTemplate?.scrollTo({ left: selectedBoardTab?.position });
      // }, 10);
      setSelectedTab({ id: selectedBoardTab.id, type: selectedBoardTab.type });
    }
  }, [selectedBoardTab]);

  //#endregion

  return (
    <div className="board">
      {/* {drawerOpen && ( */}
        <Box
          className={drawerOpen ? "sideDrawer open" : "sideDrawer"}
        >
          <BoardSidePanel
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
          />
        </Box>
      {/* )} */}
      {
        <Box
          className="boardPanel"
          // sx={{ width: drawerOpen ? "calc(100% - 250px)" : "100%"}}
        >
          <BoardPanel
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
          />
        </Box>
      }
    </div>
  );
};

export default Board;
