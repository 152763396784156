import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { GetAllSession } from "../../redux/features/session/session-slice";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ISession } from "../../types/session";
import { useNavigate } from "react-router";
import moment from "moment";
import "./session.scss";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
  TextWrapSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { SessionStatus, SessionType } from "../../constants/session";
import { AuthCode, ClientForms } from "../../types/client";
import { GetAuthcode } from "../../redux/features/client-main/client-insurance-slice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getBussinessName } from "../../constants/common";

const Session = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [filters, setFilters] = useState<{
    type: string[];
    status: string[];
    authCode: string[];
    businessUnits: string[];
    startDate: string | null;
    endDate: string | null;
  }>({
    type: [],
    status: [],
    authCode: [],
    businessUnits: [],
    startDate: null,
    endDate: null,
  });

  const allSession = useAppSelector<ISession[]>(
    (state) => state.sessionSlice.allSessions
  );
  //stores authcode
  const authcode = useAppSelector<AuthCode[]>(
    (state) => state.clientInsuranceSlice.clientAuthcode
  );

  //business units
  const businessUnits = useAppSelector(
    (state) => state.businessUnitsSlice.businessUnits
  );

  const allClients = useAppSelector<ClientForms[]>(
    (state) => state.clientListSlice.listData
  );

  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };
  const onRowSelected = (args: { data: any }) => {
    const selectedRecord = args.data; // Get the selected row's data
    const recordId = selectedRecord.id; // Adjust this based on your data structure

    // Navigate to another page with the record ID
    navigate(`/session/${recordId}`);
  };

  const scheduleTemplate = (props: any) => (
    <>
      <Typography>
        {moment(props.serviceSchedule.specificDate).format("dddd, M/D/YYYY")}
      </Typography>
      <Typography>
        {props.serviceSchedule.fromTime} - {props.serviceSchedule.toTime}
      </Typography>
    </>
  );

  const actualTemplate = (props: any) => (
    <>
      <Typography>
        {moment(props.sessionBeginDtm).format("dddd, M/D/YYYY")}
      </Typography>
      <Typography>
        {props.sessionBeginTime} - {props.sessionEndTime || "ongoing"}
      </Typography>
    </>
  );

  const updateDtmTemplate = (props: any) => (
    <span>{moment(props.updatedAt).format("MM/DD/YYYY hh:mm:ssa")}</span>
  );

  const personName = (props: any) => {
    return (
      <div>
        {props?.column.field === "clientName" && (
          <span>
            {" "}
            {props.serviceSchedule?.bookedClientId?.childFirstName}{" "}
            {props.serviceSchedule?.bookedClientId?.childLastName}
          </span>
        )}{" "}
        {props?.column.field === "providerName" && (
          <span>
            {props.serviceSchedule.bookedProviderId?.firstName}{" "}
            {props.serviceSchedule.bookedProviderId?.lastName}
          </span>
        )}
      </div>
    );
  };

  const handleFilterChange = () => {
    let filteredSession = allSession?.filter((item) => {
      if (filters.status.length && !filters.status.includes(item.status)) {
        return false;
      }
      if (
        filters.authCode.length &&
        !filters.authCode.includes(item.serviceSchedule.authCode)
      ) {
        return false;
      }
      if (
        filters.startDate &&
        filters.endDate &&
        !(
          new Date(filters.startDate) <=
            new Date(item.serviceSchedule.specificDate) &&
          new Date(filters.endDate) >=
            new Date(item.serviceSchedule.specificDate)
        )
      ) {
        return false;
      }
      if (filters.businessUnits.length) {
        const isExist = allClients.find(
          (client) =>
            client.clientBasicDetails?.id ===
              item.serviceSchedule?.bookedClientId?.id &&
            client.clientBasicDetails?.businessUnit?.some((bu) =>
              filters.businessUnits.includes(bu)
            )
        );

        if (isExist === undefined) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    });
    return filteredSession;
  };
  useEffect(() => {
    dispatch(GetAllSession());
    dispatch(GetAuthcode());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="session">
      <Box className="session-header">
        <Typography>All Sessions</Typography>
        <Box className="session-filters">
          {/* <FormControl sx={{ m: 1, width: 250 }} size="small">
            <InputLabel id="session-type-label">Type</InputLabel>
            <Select
              labelId="session-type-label"
              id="session-type-select"
              value={filters.type}
              multiple
              label="Type"
              onChange={(e) =>
                setFilters({ ...filters, type: e.target.value as string[] })
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {Object.values(SessionType).map((type) => (
                <MenuItem key={type} value={type}>
                  <Checkbox checked={filters.type.includes(type)} />
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
            <TextField
              className="DatePickerFilter"
              id="date"
              label="Start Date"
              type="date"
              value={filters.startDate}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  startDate: e.target.value,
                });
              }}
              sx={{ width: 150 }}
              InputLabelProps={{
                shrink: true,
              }}
            />

          <TextField
            className="DatePickerFilter"
            id="date"
            label="End Date"
            type="date"
            placeholder={""}
            defaultValue=""
            value={filters.endDate}
            onChange={(e) => {
              setFilters({
                ...filters,
                endDate: e.target.value,
              });
            }}
            sx={{ width: 150 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl
            sx={{ marginTop: 1, width: 150, marginRight: 1 }}
            size="small"
          >
            <InputLabel id="session-auth-label">Auth Code</InputLabel>
            <Select
              labelId="session-auth-label"
              id="session-auth-select"
              multiple
              value={filters.authCode}
              label="Auth Code"
              onChange={(e) =>
                setFilters({ ...filters, authCode: e.target.value as string[] })
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {authcode.map((auth) => (
                <MenuItem key={auth.id} value={auth.conceptValue}>
                  <Checkbox
                    checked={filters.authCode.includes(auth.conceptValue)}
                  />
                  {auth.conceptValue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ marginTop: 1, width: 150 }} size="small">
            <InputLabel id="session-AllBU-label">All BU</InputLabel>
            <Select
              labelId="session-AllBU-label"
              id="session-AllBU-select"
              multiple
              value={filters.businessUnits}
              label="All BU"
              onChange={(e) =>
                setFilters({
                  ...filters,
                  businessUnits: e.target.value as string[],
                })
              }
              renderValue={(selected: any) => {
                selected = selected.map((select: any) => {
                  return getBussinessName(select, businessUnits);
                });
                return selected.join(", ");
              }}
            >
              {businessUnits.map((bu) => (
                <MenuItem key={bu.id} value={bu.id}>
                  <Checkbox checked={filters.businessUnits.includes(bu.id)} />
                  {bu.businessUnitName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* Session Status Dropdown */}
          <FormControl sx={{ m: 1, width: 250 }} size="small">
            <InputLabel id="session-status-label">Status</InputLabel>
            <Select
              labelId="session-status-label"
              id="session-status-select"
              multiple
              value={filters.status}
              label="Status"
              onChange={(e) =>
                setFilters({ ...filters, status: e.target.value as string[] })
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {Object.values(SessionStatus).map((status) => (
                <MenuItem key={status} value={status}>
                  <Checkbox checked={filters.status.includes(status)} />
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <GridComponent
        id="grid" //must have!!
        className="gridTable ClientSession"
        dataSource={handleFilterChange()}
        allowPaging={true}
        allowFiltering={true}
        allowSorting={true}
        allowResizing={true}
        allowTextWrap={true}
        filterSettings={{ type: "Menu" }}
        textWrapSettings={textWrapSettings}
        selectionSettings={{ persistSelection: true, type: "Single" }}
        rowSelected={onRowSelected}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="clientName"
            headerText="Client"
            width="120"
            template={personName}
          />
          <ColumnDirective
            field="providerName"
            headerText="Provider"
            width="120"
            template={personName}
          />
          <ColumnDirective
            field="serviceSchedule.serviceType"
            headerText="Activity"
            width="120"
          />
          <ColumnDirective
            headerText="Auth Code"
            width="100"
            field="serviceSchedule.authCode"
          />
          <ColumnDirective
            headerText="Insurance"
            width="100"
            field="clientInsurance"
          />
          <ColumnDirective
            headerText="Schedule"
            width="200"
            template={scheduleTemplate}
          />
          <ColumnDirective
            headerText="Actual"
            width="200"
            template={actualTemplate}
          />
          <ColumnDirective
            field="updatedAt"
            headerText="Update Dtm"
            width="150"
            template={updateDtmTemplate}
          />
          <ColumnDirective field="status" headerText="Status" width="120" />
        </ColumnsDirective>
        <Inject services={[Page, Sort, Filter, Group]} />
      </GridComponent>
    </Box>
  );
};

export default Session;
