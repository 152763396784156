import axios from "../axios/axios";
import {
  BusinessSettingsInitialState,
  MessageTemplate,
  ParentSettingInitialState,
  DataCatalog,
  ParentSchedule,
  EmployeeSetting,
  IAdminScheduleSetting,
} from "../types/businessSettings";

export const postBusinessSetting = async (
  payloadData: BusinessSettingsInitialState
) => {
  try {
    const { data } = await axios.post(
      `/organization/business/intake/updateState`,
      payloadData
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//post Parent api
export const postParentSettingForm = async (
  payloadData: ParentSettingInitialState
) => {
  try {
    const { data } = await axios.post(
      `/organization/business/new/form`,
      payloadData
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//get all message template

export const getMessageTemplate = async () => {
  try {
    const { data } = await axios.get(`/template`);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//get all message template

//getTenantIds
export const getTenantIds = async () => {
  try {
    const { data } = await axios.get(`/getTenants`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//post Parent api
export const postMessageTemplateForm = async (payloadData: MessageTemplate) => {
  try {
    const { data } = await axios.post(`/template`, payloadData);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//update parent
export const updateMessageTemplateById = async (
  id: string,
  payloadData: MessageTemplate
) => {
  try {
    const { data } = await axios.put(`/template/${id}`, payloadData);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//delete perticuler message Template
export const deleteMessageTemplateById = async (id: string) => {
  try {
    const { data } = await axios.delete(`/template/${id}`);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//update parent
export const updateParentSettingForm = async (
  id: string,
  payloadData: ParentSettingInitialState
) => {
  try {
    const { data } = await axios.put(
      `/organization/business/new/form/${id}`,
      payloadData
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//delete perticuler form
export const deleteParentSettingForm = async (id: string) => {
  try {
    const { data } = await axios.delete(
      `/organization/business/new/form/${id}`
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//post data catalog
export const postDataCatalogForm = async (payloadData: DataCatalog) => {
  try {
    const { data } = await axios.post(`/datacatalog`, payloadData);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//get data catalog
export const getDataCatalog = async () => {
  try {
    const { data } = await axios.get(`/datacatalog`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//update data catalog
export const updateDataCatalogById = async (
  id: string,
  payloadData: DataCatalog
) => {
  try {
    const { data } = await axios.put(`/datacatalog/${id}`, payloadData);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//delete perticuler Data Catalog Template
export const deleteDataCatalogTemplateById = async (id: string) => {
  try {
    const { data } = await axios.delete(`/datacatalog/${id}`);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

/* not use!
//get intake forms       
export const getAllIntakeForms = async () => {
  try {
    const { data } = await axios.get(`/intakeform`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//post custom form
export const postCustomForm = async (payloadData: IntakeForms) => {
  try {
    const { data } = await axios.post(`/form`, payloadData);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//updates intakeForms
export const UpdateIntakeForm = async (
  id: string,
  payloadData: IntakeForms
) => {
  try {
    const { data } = await axios.put(`/form/${id}`, payloadData);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};


//get intake forms
export const getIntakeClientFormData = async () => {
  try {
    const { data } = await axios.get(`/intake`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};
*/

//get parent-portal settings
const getParentSetting = async () => {
  try {
    const { data } = await axios.get(`/parentSetting`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//get employee-portal settings
const getEmployeeSetting = async () => {
  try {
    const { data } = await axios.get(`/employeeSetting`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//update schedule setting
const updateScheduleSetting = async (
  type: string,
  payloadData: ParentSchedule | EmployeeSetting
) => {
  try {
    const { data } = await axios.put(`/updateSetting/${type}`, payloadData);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const getIntegrationSetting = async () => {
  try {
    const { data } = await axios.get(`/integration/setting`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

// const switchIntegrationSetting = async (isActive: boolean) => {
//   try {
//     const { data } = await axios.post(`/integration/switchSetting`, {
//       isActive,
//     });
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

const testConnectApp = async (appName: string, appCredential: any) => {
  try {
    const { data } = await axios.post(`/integration/testConnectApp`, {
      appName: appName.toLowerCase(),
      appCredential,
    });
    return data;
  } catch (e: any) {
    console.log(e);
    throw e?.response?.data;
  }
};

const connectApp = async (
  appName: string,
  appCredential: any,
  testLogin: boolean
) => {
  try {
    const { data } = await axios.post(`/integration/connectApp`, {
      appName: appName,
      appCredential,
      testLogin,
    });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const disconnectApp = async (appName: string) => {
  try {
    const { data } = await axios.post(`/integration/disconnectApp`, {
      appName: appName.toLowerCase(),
    });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const getAdminScheduleSettings = async () => {
  try {
    const { data } = await axios.get("/adminScheduleSettings");
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const updateAdminScheduleSetting = async (payload: IAdminScheduleSetting) => {
  try {
    const { data } = await axios.put("/updateAdminScheduleSettings", payload);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const getSyncDataRequestsByAppName = async (appName: string) => {
  try {
    const { data } = await axios.get(`/integration/get-all?appName=${appName}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

// const connectMotivityApp = async (
//   appName: string,
//   appCredential: any,
//   testLogin: boolean
// ) => {
//   try {
//     const { data } = await axios.post(`integration/connectApp/motivity`, {
//       appName: appName,
//       appCredential,
//       testLogin,
//     });
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

const getlastSyncedDTM = async (appName: String) => {
  try {
    const { data } = await axios.get(
      `/integration/lastSyncedDtm?appName=${appName}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

// const linkSessionForm = async (
//   formId: string,
//   authId: string[],
//   insuranceId: string[],
//   linkType: string
// ) => {
//   try {
//     const { data } = await axios.put(`/datacatalog/linkForm/${formId}`, {
//       authIds: authId,
//       insuranceIds: insuranceId,
//       linkType,
//     });
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

const syncIntegration = async (syncRequestIds: string[]) => {
  try {
    const { data } = await axios.post("/integration/sync", {
      syncRequestIds,
    });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const BusinessSetting = {
  postBusinessSetting,
  postParentSettingForm,
  postMessageTemplateForm,
  postDataCatalogForm,
  updateParentSettingForm,
  deleteParentSettingForm,
  getMessageTemplate,
  getDataCatalog,
  updateDataCatalogById,
  updateMessageTemplateById,
  deleteMessageTemplateById,
  deleteDataCatalogTemplateById,
  // getAllIntakeForms,

  // postCustomForm,
  // UpdateIntakeForm,
  // getIntakeClientFormData,

  updateScheduleSetting,
  getParentSetting,
  getEmployeeSetting,
  getIntegrationSetting,
  // switchIntegrationSetting,
  testConnectApp,
  connectApp,
  disconnectApp,
  // connectMotivityApp,
  getAdminScheduleSettings,
  updateAdminScheduleSetting,
  getSyncDataRequestsByAppName,
  getlastSyncedDTM,
  // linkSessionForm,
  syncIntegration,
};

export default BusinessSetting;
