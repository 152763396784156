import axios from "../axios/axios";
import { sessionSetting } from "../types/session";

const getAllSessions = async () => {
  try {
    const { data } = await axios.get("/session/sessions");
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const getSessionById = async (id: string) => {
  try {
    const { data } = await axios.get(`/session/session/${id}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const updateSessionFormData = async (
  id: string,
  payload: {
    formData: any;
    reviewData: any;
  }
) => {
  try {
    const { data } = await axios.put(
      `/formrequest/${id}/updateReviewData`,
      payload
    );
    return data;
  } catch (error: any) {
    throw error.response?.data;
  }
};

const UpdateReviewComment = async (
  id: string,
  payload: { comment: string }
) => {
  try {
    const { data } = await axios.patch(
      `/formrequest/${id}/updateComment`,
      payload
    );
    return data;
  } catch (error: any) {
    throw error.response?.data;
  }
};

//session settings
const getSessionSettings = async () => {
  try {
    const { data } = await axios.get("/sessionSetting");
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const updateSessionSettings = async (payload: sessionSetting) => {
  try {
    const { data } = await axios.put("/sessionSetting", payload);
    return data;
  } catch (error: any) {
    throw error.response?.data;
  }
};

//session settings
const createSessionFormsZip = async (formIds: string[]) => {
  try {
    const { data } = await axios.post(
      "/session/createSessionFormsZip",
      formIds
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const sessionService = {
  getAllSessions,
  getSessionById,
  updateSessionFormData,
  UpdateReviewComment,
  getSessionSettings,
  updateSessionSettings,
  createSessionFormsZip,
};
