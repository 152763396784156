import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { NewDataCatalog } from "../../../types/businessSettings";
import { ClientForms, OtherAddresses } from "../../../types/client";
import { MatchResult } from "../../../types/schedule";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import DiscoverBookingEditModal from "../booking-scheduler/DiscoverBookingEditModal";
import { ProviderForms } from "../../../types/provider";
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  GridComponent,
  Group,
  GroupSettingsModel,
  Inject,
  Page,
  PdfExport,
  Resize,
  SelectionSettingsModel,
  Sort,
  TextWrapSettingsModel,
  Toolbar,
} from "@syncfusion/ej2-react-grids";

interface IAdditionOption {
  isEndAuth: boolean;
  isEndMonth: boolean;
  isCustom: boolean;
}

enum RepeatOptions {
  EndOfAuth = "till end of auth",
  EndOfMonth = "till end of month",
  CustomEnd = "till custom end",
}

const ScheduleMatchList: React.FC<{
  // item: MatchResult;
  // index: number;
  selectedClientData: ClientForms | null;
  isScheduleBooked: boolean;
  setIsScheduledBooked: Dispatch<SetStateAction<boolean>>;
  setLastBookedDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  selectedProviderData: ProviderForms | null;
  setSelectedMatchResult: Dispatch<SetStateAction<string[]>>;
  selectedMatchResult: string[];
  entityType: string;
  checkStartDate: Date | undefined;
  checkEndDate: Date | undefined;
  matchResults: MatchResult[];
}> = ({
  // item,
  // index,
  selectedClientData,
  isScheduleBooked,
  setIsScheduledBooked,
  setLastBookedDate,
  selectedProviderData,
  setSelectedMatchResult,
  entityType,
  matchResults,
}) => {
  const repeatOptions = [
    RepeatOptions.EndOfAuth as string,
    RepeatOptions.EndOfMonth as string,
    RepeatOptions.CustomEnd as string,
  ];
  const [popUp, setPopup] = useState<boolean>(false);
  const [selectedSlot, setSelectedSlot] = useState<string>();
  const [selectedDate, setSelectedDate] = useState<string[]>([]);
  const [repeatService, setRepeatService] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<MatchResult>();

  //store custom end
  const [customEnd, setCustomEnd] = useState<string>("");
  const [isEndMonth, setIsEndMonth] = useState<boolean>(false);

  // const dispatch = useDispatch();
  //#region grid region
  let gridInstance: GridComponent;
  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };
  let refresh: boolean;
  function load(this: any) {
    refresh = this?.refreshing;
  }

  function dataBound() {
    if (refresh && gridInstance) {
      gridInstance.groupColumn("subject");
      refresh = false;
      gridInstance.refresh(); //must have!! otherwise, has white-screen.
    }
  }

  const selectionSettings: SelectionSettingsModel = {
    type: "Multiple",
    mode: "Both",
  };
  const handleDateSelection = (value: string[]) => {
    let specialOptions = repeatOptions;
    if (value?.length === 0) {
      setRepeatService(0);
      setIsEndMonth(false);
      setCustomEnd("");
      setSelectedDate([]);
      return;
    }

    let filteredSpecialOptions = value.filter((val) =>
      specialOptions.includes(val)
    );
    let nonSpecialOptions = value.filter(
      (val) => !specialOptions.includes(val)
    );

    //get last value whether it is special option
    let finalValue: any[] = [];
    let lastValue = value[value.length - 1];

    if (specialOptions.includes(lastValue)) {
      //if last value is special option, remove all other values
      nonSpecialOptions = [];
      filteredSpecialOptions = [lastValue];
    } else {
      //if last value is not special option, remove all special options
      filteredSpecialOptions = [];
    }

    finalValue = [...filteredSpecialOptions, ...nonSpecialOptions];
    setSelectedDate(finalValue);
  };

  const handleOpenBookingModal = (item: any, dates: string[], slot: string) => {
    if (selectedDate.includes(RepeatOptions.CustomEnd)) {
      setRepeatService(3); //3: custom end
      setCustomEnd(selectedDate[selectedDate.length - 1]);
      setSelectedDate(selectedDate.slice(0, selectedDate.length - 1));
      setPopup(true);
      return;
    }

    // check end of month
    if (selectedDate.includes(RepeatOptions.EndOfMonth)) {
      setRepeatService(1); //1: end of month
      const now = new Date();
      const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      const endOfMonth = new Date(nextMonth.getTime() - 1);
      setCustomEnd(endOfMonth.toString());
      //filter dates between today and endOfMonth
      const filteredDates = item.dates.filter(
        (date: string) =>
          new Date(date).getTime() >= new Date().getTime() &&
          new Date(date).getTime() <= endOfMonth.getTime()
      );
      setSelectedDate(filteredDates);
      setIsEndMonth(true);
      setPopup(true);
      return;
    }

    // check end of month
    if (selectedDate.includes(RepeatOptions.EndOfAuth)) {
      setRepeatService(2); //1: end of auth
      //filter dates between today and endOfAuth
      const filteredDates = item.dates.filter(
        (date: string) =>
          new Date(date).getTime() >= new Date().getTime() &&
          new Date(date).getTime() <=
            new Date(item.authorizations[0]?.authPeriod?.endDate!).getTime()
      );
      setSelectedDate(filteredDates);
      setIsEndMonth(false);
      setCustomEnd("");
      setPopup(true);
      return;
    }

    // single select
    // setSelectedDate(serviceDate);
    setSelectedItem(item);
    setSelectedSlot(slot);
    setRepeatService(0); //0: single select
    setCustomEnd("");
    setPopup(true);
  };

  //handles select checkbox
  // const handleCheckboxChange = () => {
  //   let uniqueDetails =
  //     entityType === "Client"
  //       ? item.baseProvider?.employmentDetails.id + item.dayOfWeek
  //       : item.baseClient?.clientBasicDetails.id + item.dayOfWeek;
  //   if (uniqueDetails) {
  //     if (selectedMatchResult.includes(uniqueDetails)) {
  //       // Uncheck the checkbox: remove the id from selectedMatchResult
  //       setSelectedMatchResult(
  //         selectedMatchResult.filter(
  //           (selectedId) => selectedId !== uniqueDetails
  //         )
  //       );
  //     } else {
  //       // Check the checkbox: add the id to selectedMatchResult
  //       setSelectedMatchResult([...selectedMatchResult, uniqueDetails]);
  //     }
  //   }
  // };
  const textTemplate = (props: any) => {
    return (
      <div>
        {props?.column.field === "client" && (
          <>
            <Typography>{props.clientName}</Typography>
            <Typography>
              {props.weeklyAvgBookedForClient.toFixed(2)}
              {" / "}
              {props.weeklyAvgRemainedAuthForClient.toFixed(2)}
              {" ("}
              {(props.utilizationPcntByAuthorizationForClient * 100).toFixed(2)}
            </Typography>
          </>
        )}

        {props?.column.field === "employee" && (
          <>
            <Typography>{props.providerName}</Typography>
            <Typography>
              {props.weeklyAvgBookedForProvider.toFixed(2)}
              {" / "}
              {props.weeklyAvgRemainedByAvailabilityForProvider.toFixed(2)}
              {" ("}
              {(props.utilizationPcntByAvailabilityForProvider * 100).toFixed(
                2
              )}
              {"%)"}
            </Typography>
          </>
        )}
        {props?.column.field === "auth" && (
          <Typography>
            {props.authorizations[0]?.authorizationName}
            {"\n"}
            {props.authorizations[0]?.authPeriod?.startDate}
            {" - "}
            {props.authorizations[0]?.authPeriod?.endDate}
            {"\n"}
            {props.authCode}
            {" remain: "}
            {props.remainingAuthHours.toFixed(2)}
          </Typography>
        )}
        {props?.column.field === "distance" && (
          <Typography>{props.distance}</Typography>
        )}
        {props?.column.field === "week" && (
          <Typography>{props.weeks}</Typography>
        )}
        {props?.column.field === "day" && (
          <Typography>{props.dayOfWeek}</Typography>
        )}
        {props?.column.field === "matchOn" && (
          <span style={{ wordBreak: "break-all" }}>
            {props.matchOn.join("\n")}
          </span>
        )}
        {props?.column.field === "open" &&
          props?.slotSummary &&
          props?.slotSummary
            ?.trim()
            .split("\n")
            .map((item: string) => (
              <Typography>{props.slotSummary}</Typography>
            ))}
      </div>
    );
  };

  const DataTemplate = (props: any) => {
    const [dates, setDate] = useState<string[]>([props.dates[0]]);
    const [slots, setSlots] = useState<string>(props.time[0]);
    return (
      <>
        {props.column.field === "date" && (
          <FormControl sx={{ m: 0, width: 135 }} size="small">
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={dates}
              onChange={({ target: { value } }: any) => {
                setDate(value);
                handleDateSelection(value);
              }}
              input={<OutlinedInput />}
              renderValue={(selected: any[]) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: 350, width: 200 },
                },
              }}
            >
              {[...repeatOptions, ...props.dates].map((date) => (
                <MenuItem key={date} value={date}>
                  <Checkbox checked={dates && dates.indexOf(date) > -1} />
                  <ListItemText primary={date} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {props.column.field === "slot" && (
          <FormControl sx={{ m: 1, width: 200 }} size="small">
            <Select
              value={slots}
              onChange={({ target: { value } }) => {
                setSlots(value);
                setSelectedSlot(value);
              }}
              input={<OutlinedInput />}
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: 350, width: 150 },
                },
              }}
            >
              {props.time.map((name: string) => (
                <MenuItem key={name} value={name}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {props.column.field === "book" && (
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenBookingModal(props, dates, slots);
            }}
          >
            Book
          </Button>
        )}
      </>
    );
  };
  const rowSelected = () => {
    if (gridInstance) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let uniqueIds: string[] = [];
      const selectedrecords: any[] = gridInstance.getSelectedRecords();
      selectedrecords.forEach((item) => {
        if (entityType === "Client" && item?.baseProvider) {
          uniqueIds.push(
            item.baseProvider?.employmentDetails.id + item.dayOfWeek
          );
        } else if (entityType === "Provider" && item.baseClient) {
          uniqueIds.push(
            item.baseClient?.clientBasicDetails.id + item.dayOfWeek
          );
        }
      });
      setSelectedMatchResult(uniqueIds);
    }
  };

  const gridComponent = useMemo(
    () => (
      <GridComponent
        id="grid" //must have!!
        className="gridTable gridTable--lg"
        dataSource={matchResults}
        dataBound={dataBound.bind(this)}
        allowPaging={true}
        ref={(grid) => (gridInstance = grid as GridComponent)}
        statelessTemplates={["directiveTemplates"]}
        load={load}
        width="auto"
        height="650"
        selectionSettings={selectionSettings}
        allowGrouping={true}
        groupSettings={groupOptions}
        allowFiltering={true}
        allowSorting={true}
        allowResizing={true}
        allowTextWrap={true}
        filterSettings={{ type: "Menu" }}
        textWrapSettings={textWrapSettings}
        allowPdfExport={true}
        allowExcelExport={true}
        checkBoxChange={rowSelected}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            visible={false}
            headerText="ID"
            isPrimaryKey={true}
          />
          <ColumnDirective type="checkbox" width="50" textAlign="Left" />
          <ColumnDirective
            headerText={entityType === "Provider" ? "Employee" : "Client"}
            width="160"
            textAlign="Left"
            field={entityType === "Provider" ? "employee" : "client"}
            template={textTemplate}
          />
          <ColumnDirective
            headerText={entityType === "Provider" ? "Client" : "Employee"}
            width="180"
            textAlign="Left"
            field={entityType === "Provider" ? "client" : "employee"}
            template={textTemplate}
          />
          <ColumnDirective
            headerText="Distance"
            width="100"
            textAlign="Left"
            field="distance"
            template={textTemplate}
          />
          <ColumnDirective
            headerText="Auth"
            width="200"
            textAlign="Left"
            field="auth"
            template={textTemplate}
          />
          <ColumnDirective
            headerText="Week"
            width="100"
            textAlign="Left"
            field="week"
            template={textTemplate}
          />
          <ColumnDirective
            headerText="Day"
            width="100"
            textAlign="Left"
            field="day"
            template={textTemplate}
          />
          <ColumnDirective
            headerText="Date"
            width="150"
            textAlign="Left"
            field="date"
            template={DataTemplate}
          />
          <ColumnDirective
            headerText="Open"
            width="170"
            textAlign="Left"
            field="open"
            template={textTemplate}
          />
          <ColumnDirective
            headerText="Slot"
            width="220"
            textAlign="Left"
            field="slot"
            template={DataTemplate}
          />
          <ColumnDirective
            headerText="MatchOn"
            width="150"
            textAlign="Left"
            field="matchOn"
            template={textTemplate}
          />
          <ColumnDirective
            headerText="Book"
            width="80"
            textAlign="Left"
            field="book"
            template={DataTemplate}
          />
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Group,
            ContextMenu,
            Toolbar,
            Resize,
            DetailRow,
            Filter,
            Sort,
            ExcelExport,
            PdfExport,
          ]}
        />
      </GridComponent>
    ),
    [matchResults]
  );
  // useEffect(() => {
  //   setSelectedDate([item.dates[0]]);
  //   setSelectedSlot(item.time[0]);
  // }, [item.dates]);

  useEffect(() => {
    if (isScheduleBooked) {
      setLastBookedDate(new Date(selectedDate[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScheduleBooked]);

  return (
    <>
      {gridComponent}

      {popUp &&
        selectedItem?.baseProvider !== null &&
        selectedClientData !== null && (
          <DiscoverBookingEditModal
            pageTitle={"Add Booking"}
            clientData={selectedClientData}
            provider={selectedItem?.baseProvider}
            isOpen={popUp}
            isEdit={false}
            isNew={true}
            closePopup={setPopup}
            argsdata={undefined}
            setIsDataEdited={setIsScheduledBooked}
            allSelectedClients={[selectedClientData]}
            providersData={[selectedItem?.baseProvider]}
            fromPlanning={false}
            isAutoPlanned={true}
            autoPlanData={{
              isClient: true,
              selectedSlot: selectedSlot,
              repeatService: repeatService,
              selectedDate: !selectedDate.length ? [selectedItem?.dates[0]]: selectedDate,
              selectedDays: [selectedItem?.dayOfWeek],
              isEndMonth: isEndMonth,
              customEnd: customEnd,
              authId: selectedItem?.authorizations[0]?.id,
              authCode: selectedItem?.authCode,
            }}
          />
        )}

      {popUp &&
        selectedItem?.baseClient !== null &&
        selectedProviderData !== null && (
          <DiscoverBookingEditModal
            pageTitle={"Add Booking"}
            provider={selectedProviderData}
            clientData={selectedItem?.baseClient}
            isOpen={popUp}
            isEdit={false}
            isNew={true}
            closePopup={setPopup}
            argsdata={undefined} //calendar event data
            setIsDataEdited={setIsScheduledBooked}
            allSelectedClients={[selectedItem?.baseClient]}
            providersData={[selectedProviderData]}
            fromPlanning={false}
            isAutoPlanned={true}
            autoPlanData={{
              isClient: false,
              selectedSlot: selectedSlot,
              repeatService: repeatService,
              selectedDate: !selectedDate.length ? [selectedItem?.dates[0]]: selectedDate,
              selectedDays: [selectedItem?.dayOfWeek],
              isEndMonth: isEndMonth,
              customEnd: customEnd,
              authId: selectedItem?.authorizations[0]?.id,
              authCode: selectedItem?.authCode,
            }}
          />
        )}
    </>
  );
};

export default ScheduleMatchList;
